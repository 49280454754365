<template>
  <div :class="$style.container">
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="chatsList"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'chat'">
          <router-link
            :class="$style.chat"
            :to="`chats/${props.row.dealerAccountId}`"
          >
            <div :class="$style.name">
              {{ props.row.chatName }}
              <span>{{ formatDateTime(props.row.createdAt) }}</span>
            </div>
            <div :class="$style.text">
              <span :class="$style.avatar">
                {{ props.row.isWrittenManager ? 'Вы' : props.row.chatName[0] }}
              </span>
              {{ props.row.text }}
              <Icon
                v-if="props.row.isReadDealer"
                name="check"
                :class="$style.icon"
              />
            </div>
          </router-link>
        </span>
      </template>
    </vue-good-table>
    <Pagination
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="chatsPage"
      :lastPage="pageChatsCount()"
    />
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatDateTime } from '@/helpers/index.js'
import delivery from '@/delivery'

import Icon from '@/components/atoms/Icon'
import Pagination from '@/components/atoms/Paginations.vue'
import Loader from '@/components/atoms/LoadingBar.vue'

export default {
  components: { Icon, Pagination, Loader },
  data() {
    return {
      columns: [
        {
          label: 'Чат',
          field: 'chat',
          sortable: false,
        },
      ],
      isLoading: false,
    }
  },
  async mounted() {
    await this.getData()
    await this.initWsChat()
  },
  beforeDestroy() {
    delivery.AddwineCore.WsChatActions.close()
  },
  computed: {
    chatsList() {
      return this.$store.state.chats.list
    },
    chatsPage() {
      return this.$store.state.chats.page
    },
  },
  methods: {
    ...mapGetters({
      pageChatsCount: 'pageChatsCount',
    }),
    ...mapActions(['getChats', 'initWsChat']),
    async goNext() {
      if (this.chatsPage < this.pageChatsCount()) {
        this.$store.commit('setChatsPage', this.chatsPage + 1)
        await this.getData()
      }
    },
    async goPrev() {
      if (this.chatsPage > 1) {
        this.$store.commit('setChatsPage', this.chatsPage - 1)
        await this.getData()
      }
    },
    async getData() {
      this.isLoading = true
      await this.getChats()
      this.isLoading = false
    },
    formatDateTime(date) {
      return formatDateTime(date)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  h2 {
    padding: 1rem 0;
  }
  .chat {
    text-decoration: initial;
    color: initial;
    .name {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: bold;
      > span {
        font-weight: normal;
        font-size: 0.8rem;
      }
    }
    .text {
      display: flex;
      align-items: center;
      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $light-gray;
        min-width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
      .icon {
        margin-left: 1rem;
      }
    }
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: red;
  }
  .successStatus {
    background-color: green;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
}
</style>
